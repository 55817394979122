import React from 'react'
import { Form, Input, InputNumber, Icon, Tooltip } from 'antd'
import moment from 'moment'

const { TextArea } = Input;

export default function (props) {
  const { getFieldDecorator, transactionId, customerName, restaurant, checkIn, amount, note, hasUpcomingPlan, planStatus } = props

  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <p>You are reversing payment for:<br />Customer Name: <b>{customerName ? customerName : null}</b> <br />Restaurant Name: <b>{restaurant ? restaurant : null}</b><br />Time: <b>{checkIn ? moment(checkIn).format('DD/MM/YYYY [on] hh:mm:ss A') : null}</b><br />Paid Amount: <b>{amount ? amount : null}</b> Points</p>
      <Form.Item label="transaction id" style={{ display: 'none' }}>
        {getFieldDecorator('transactionId', {
          initialValue: transactionId
        })(
          <Input disabled={true} />
        )}
      </Form.Item>
      <Form.Item label="Actual amount" style={{ display: 'none' }}>
        {getFieldDecorator('actualAmount', {
          initialValue: amount
        })(
          <Input disabled={true} />
        )}
      </Form.Item>
      <Form.Item label={
        <div>
          <span>Reverse Amount for plan wallet</span>
          <Tooltip title={planStatus === 'ACTIVE' ? "Points expire with current plan" : "User does not have active plan"}>
            <Icon type="info-circle" style={{ margin: '0 5px' }} />
          </Tooltip>
        </div>
      }>
        {getFieldDecorator('points',
          {
            rules: [
              { pattern: /^[1-9]+[0-9]*$/g, message: 'Invalid amount' }],
          })(<InputNumber precision={0} type="number" style={{ width: '100%' }} disabled={planStatus !== 'ACTIVE'} />)}
      </Form.Item>
      <Form.Item label={
        <div>
          <span>Reverse Amount for upcoming plan wallet</span>
          <Tooltip title={hasUpcomingPlan ? "Points expire with upcoming plan" : "User does not have upcoming plan"}>
            <Icon type="info-circle" style={{ margin: '0 5px' }} />
          </Tooltip>
        </div>
      }>
        {getFieldDecorator('lastOverDueAmount',
          {
            rules: [
              { pattern: /^[1-9]+[0-9]*$/g, message: 'Invalid amount' }],
          })(<InputNumber precision={0} type="number" style={{ width: '100%' }} disabled={!hasUpcomingPlan} />)}
      </Form.Item>
      <Form.Item label={
        <div>
          <span>Reverse Amount for topup wallet</span>
          <Tooltip title={planStatus === 'ACTIVE' ? "Points never expires" : "User does not have active plan"}>
            <Icon type="info-circle" style={{ margin: '0 5px' }} />
          </Tooltip>
        </div>
      }>
        {getFieldDecorator('topUp',
          {
            rules: [
              { pattern: /^[1-9]+[0-9]*$/g, message: 'Invalid amount' }],
          })(<InputNumber precision={0} type="number" style={{ width: '100%' }} disabled={planStatus !== 'ACTIVE'} />)}
      </Form.Item>
      <Form.Item label="Note">
        {getFieldDecorator('note', {
          initialValue: note
        })(
          <TextArea rows={4} />
        )}
      </Form.Item>
    </Form>
  )
}
