import React, { useState, useRef, useContext } from 'react'
import { Row, Col, Table, Typography, Pagination, Button, Input, Icon, notification } from 'antd'
import { ExportToCsv } from 'export-to-csv';
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import client from '../../apollo'
import { AppContext } from '../../AppContext'
import { CHECKINS_CONNECTIONS, GET_CHECKINS, REVERSE_PAYMENT } from './graphql/Queries'
import Meta from '../../components/Meta'
import ReverseModal from './components/ReverseModal'
import ExportModal from './components/ExportModal'
import ReverseDetailsModal from './components/ReverseDetailsModal'
import titleCase from '../../components/titleCase'
import ErrorMessages from '../../common/ErrorMessages'
import './check-in.css'

const { Title } = Typography
const options = {
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: 'ikoverk-checkins'
};
const csvExporter = new ExportToCsv(options);

export default function CheckIns(props) {
  const { state } = useContext(AppContext)
  const [isSubmit, setSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showReverseDetailsModal, setShowReverseDetailsModal] = useState(false)
  const [editableData, setEditableData] = useState("")
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState("checkIn_DESC")
  const [filters, setFilters] = useState({})
  let { data: checkInData, error: checkInError, loading: checkInLoad, networkStatus, fetchMore } = useQuery(GET_CHECKINS, { variables: { first: 10, filters, orderByFilter }, fetchPolicy: 'network-only' })
  let { data: checkInDataCount, error: checkInDataCountError } = useQuery(CHECKINS_CONNECTIONS, { variables: { filters, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !checkInDataCountError ? (checkInDataCount && checkInDataCount.checkInsConnection) ? checkInDataCount.checkInsConnection.aggregate.count : 1 : 1
  const saveFormRef = useRef()
  const exportFormRef = useRef()
  const canReverse = state && state.currentUser && state.currentUser.role && state.currentUser.role === 'ADMIN'

  function handleCancel() {
    setShowReverseDetailsModal(false)
    setShowModal(false)
    setShowExportModal(false)
    saveFormRef.current.props.form.resetFields()
    exportFormRef.current.props.form.resetFields()
    setSubmit(false)
    setEditableData("")
  }

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      const { topUp = 0, lastOverDueAmount = 0, points = 0, actualAmount, transactionId, note } = values
      const totalAmount = points + lastOverDueAmount + topUp
      if (actualAmount < totalAmount) {
        openNotification('error', 'Total amount can not be greater then actual amount!')
        return
      } else if (totalAmount === 0) {
        openNotification('error', 'Total amount cannot be zero or empty!')
        return
      }
      try {
        await client.mutate({
          mutation: REVERSE_PAYMENT,
          variables: {
            transactionID: transactionId,
            points: points,
            lastOverDueAmount: lastOverDueAmount,
            topUp: topUp,
            notes: note,
            amount: actualAmount
          },
          refetchQueries: [{
            query: GET_CHECKINS,
            variables: { filters, orderByFilter },
            fetchPolicy: "network-only"
          }]
        }).then(res => {
          openNotification('success', 'Payment successfully reversed!')
          setShowModal(false)
          setSubmit(false)
          setEditableData("")
          form.resetFields()
        })
      } catch (e) {
        console.log(e)
        setSubmit(false)
        openNotification('error', 'Something Went Wrong!')
      }
    })
  }

  function handleExport() {
    const form = exportFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      const { restaurant, dateRange } = values
      let restFilter = {}
      if (restaurant && restaurant.length !== 0) {
        restFilter['OR'] = restaurant.map(resId => ({ id: resId }))
      }
      const data = {
        restaurant: restFilter,
        checkIn_gte: `${dateRange[0].format('YYYY-MM-DD')}T00:00:00.000Z`,
        checkIn_lte: `${dateRange[1].format('YYYY-MM-DD')}T23:59:59.999Z`
      }
      try {
        client.query({
          query: GET_CHECKINS,
          variables: {
            filters: data,
            orderByFilter: 'checkIn_ASC',
          },
          fetchPolicy: 'network-only'
        }).then((data) => {
          if (data && data.data && data.data.checkIns && data.data.checkIns.length > 0) {
            const csvData = data.data.checkIns.map((checkIn) => (
              {
                // id: checkIn.user && checkIn.user.id ? checkIn.user.id : null,
                'Transaction Id': checkIn.transaction && checkIn.transaction.id ? checkIn.transaction.id : '-',
                'Customer Name': `${checkIn.user && checkIn.user.firstName ? checkIn.user.firstName : '-'} ${checkIn.user && checkIn.user.lastName ? checkIn.user.lastName : '-'}`,
                'Restaurant': `${checkIn.restaurant && checkIn.restaurant.name ? checkIn.restaurant.name : '-'}, ${checkIn.restaurant && checkIn.restaurant.address ? checkIn.restaurant.address : ''}`,
                'Check In Time': checkIn.checkIn ? moment(checkIn.checkIn).format('DD-MM-YYYY hh:mm A') : '-',
                'Check Out Time': checkIn.checkOut ? moment(checkIn.checkOut).format('DD-MM-YYYY hh:mm A') : '-',
                'Duration (Minutes)': checkIn.duration,
                'Payment Time': checkIn.transaction && checkIn.transaction.createdAt ? moment(checkIn.transaction.createdAt).format('DD-MM-YYYY hh:mm A') : '-',
                'Amount': checkIn.transaction && checkIn.transaction.amount ? checkIn.transaction.amount : '-',
                'Commission': checkIn.transaction && checkIn.transaction.commissionAmount ? Math.round(checkIn.transaction.commissionAmount) : '-',
                // discount: checkIn.transaction && checkIn.transaction.discountAmount ? Math.round(checkIn.transaction.discountAmount) : '-',
                'Reverse Transaction Amount': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.amount ? Math.abs(checkIn.transaction.reverseTransactionData.amount) : '-',
                'Reverse Transaction Note': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.notes ? checkIn.transaction.reverseTransactionData.notes : '-',
                'Reverse Transaction Time': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.createdAt ? moment(checkIn.transaction.reverseTransactionData.createdAt).format('DD-MM-YYYY hh:mm A') : '-',
              }
            ))
            csvExporter.generateCsv(csvData)
          } else {
            openNotification('info', 'Selected Restaurant have 0 checkins in selected time period!')
          }
          setSubmit(false)
        })
      } catch (e) {
        setSubmit(false)
        openNotification('error', ErrorMessages(e))
        console.log(e)
      }
    })
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={input => input && input.focus()}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      // sorter: (a, b) => a.age - b.age
    }
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    setCurrentPageNumber(1)
    confirm()
    let tempFilters = {}
    if (dataIndex === 'customerName') {
      tempFilters['user'] = {
        "OR":
          [{ "lastName_contains": selectedKeys[0].toLowerCase() },
          { "lastName_contains": selectedKeys[0].toUpperCase() },
          { "lastName_contains": titleCase(selectedKeys[0]) },
          { "lastName_contains": selectedKeys[0] },
          { "firstName_contains": selectedKeys[0].toLowerCase() },
          { "firstName_contains": selectedKeys[0].toUpperCase() },
          { "firstName_contains": titleCase(selectedKeys[0]) },
          { "firstName_contains": selectedKeys[0] },
          ]
      }
    } else if (dataIndex === 'restaurant') {
      tempFilters['restaurant'] = {
        "OR":
          [
            { "name_contains": selectedKeys[0].toLowerCase() },
            { "name_contains": selectedKeys[0].toUpperCase() },
            { "name_contains": titleCase(selectedKeys[0]) },
            { "name_contains": selectedKeys[0] },
            { "address_contains": selectedKeys[0].toLowerCase() },
            { "address_contains": selectedKeys[0].toUpperCase() },
            { "address_contains": titleCase(selectedKeys[0]) },
            { "address_contains": selectedKeys[0] }
          ]
      }
    } else if (dataIndex === 'transactionId') {
      tempFilters['transaction'] = {
        "OR":
          [
            { "id_contains": selectedKeys[0] },
          ]
      }
    }
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters();
    let tempFilters = {}
    if (dataIndex === 'customerName') {
      tempFilters['user'] = {
        "OR":
          [{ "lastName_contains": "" },
          { "firstName_contains": "" }
          ]
      }
    } else if (dataIndex === 'restaurant') {
      tempFilters['restaurant'] = {
        "OR":
          [{ "name_contains": "" },
          { "address_contains": "" }
          ]
      }
    } else if (dataIndex === 'transactionId') {
      tempFilters['transaction'] = {
        "OR":
          [{ "id_contains": "" },
          ]
      }
    }
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReverse(record) {
    setEditableData(record)
    setShowModal(true)
  }

  function showReverseDetail(data) {
    setEditableData(data)
    setShowReverseDetailsModal(true)
  }

  const column = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionID',
      width: '10%',
      render: (transactionID) => transactionID ? transactionID : '-',
      ...getColumnSearchProps('transactionId')
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      width: '10%',
      render: (text, record, index) => record.customerName ? <NavLink to={`/customers/${record.id}`} className="discussions-subject">{`${record.customerName}`}</NavLink> : '-',
      ...getColumnSearchProps('customerName')
    },
    {
      title: 'Restaurant',
      dataIndex: 'restaurant',
      width: '15%',
      render: (restaurant) => restaurant ? restaurant : '-',
      ...getColumnSearchProps('restaurant')
    },
    {
      title: 'Amount (₹)',
      dataIndex: 'amount',
      width: '13%',
      render: (amount, record, index) => amount ?
        <div>
          <span>{record.reverseTransactionData ? amount - Math.abs(record.reverseTransactionData.amount) : amount}</span>
          <span style={{ float: 'right', cursor: 'pointer' }}>
            {
              record.reverseTransactionData ?
                <Icon type="info-circle" theme="twoTone" onClick={() => showReverseDetail(record)} />
                :
                canReverse ? <Button type="primary" size='small' onClick={() => handleReverse(record)}>Reverse</Button> : null
            }</span>
        </div>
        :
        '-',
    },
    {
      title: 'Check-In Time',
      dataIndex: 'checkIn',
      width: '15%',
      render: (time) => time && moment(time).format('DD/MM/YYYY, hh:mm A'),
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      width: '10%',
      render: (duration) => `${parseInt(duration / 60) ? `${parseInt(duration / 60)} hours ` : ''}${Math.round(duration % 60)} minutes`
    },
    {
      title: 'Commission (₹)',
      dataIndex: 'commission',
      width: '10%',
      render: (commission) => commission ? Math.round(commission) : '-'
    },
    // {
    //   title: 'Discount (₹)',
    //   dataIndex: 'discount',
    //   width: '5%',
    //   render: (discount) => discount ? Math.round(discount) : '-'
    // },
    {
      title: 'Paid At',
      dataIndex: 'createdAt',
      width: '15%',
      render: (createdAt) => createdAt ? moment(createdAt).format('DD/MM/YYYY, hh:mm A') : '-',
      sorter: (a, b) => a.age - b.age
    }
  ]
  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataindex: col.dataindex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!checkInError && checkInData && checkInData.checkIns) {
    postTableData = checkInData.checkIns.map((checkIn, key) => (
      {
        key: key.toString(),
        id: checkIn.user && checkIn.user.id ? checkIn.user.id : null,
        transactionID: checkIn.transaction && checkIn.transaction.id ? checkIn.transaction.id : null,
        amount: checkIn.transaction && checkIn.transaction.amount ? checkIn.transaction.amount : null,
        customerName: `${checkIn.user && checkIn.user.firstName ? checkIn.user.firstName : null} ${checkIn.user && checkIn.user.lastName ? checkIn.user.lastName : null}`,
        restaurant: `${checkIn.restaurant && checkIn.restaurant.name ? checkIn.restaurant.name : null}, ${checkIn.restaurant && checkIn.restaurant.address ? checkIn.restaurant.address : ''}`,
        createdAt: checkIn.transaction && checkIn.transaction.createdAt ? checkIn.transaction.createdAt : null,
        commission: checkIn.transaction && checkIn.transaction.commissionAmount ? Math.round(checkIn.transaction.commissionAmount) : null,
        // discount: checkIn.transaction && checkIn.transaction.discountAmount ? Math.round(checkIn.transaction.discountAmount) : null,
        duration: checkIn.duration,
        checkIn: checkIn.checkIn,
        transactionId: checkIn.transaction && checkIn.transaction.id ? checkIn.transaction.id : null,
        hasUpcomingPlan: checkIn.user ? checkIn.user.hasUpcomingPlan : null,
        planStatus: checkIn.user && checkIn.user.planStatus ? checkIn.user.planStatus : null,
        reverseTransactionData: checkIn.transaction && checkIn.transaction.reverseTransactionData ? checkIn.transaction.reverseTransactionData : null
      }
    ))
  }

  function refresh() {
    setTableLoading(true)
    client.query({
      query: GET_CHECKINS,
      variables: { first: 10, filters: {}, orderByFilter },
      fetchPolicy: 'network-only'
    }).then(result => {
      checkInData = result
      client.query({
        query: CHECKINS_CONNECTIONS,
        variables: { filters, orderByFilter },
        fetchPolicy: 'network-only'
      }).then(result => checkInDataCount = result)
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setCurrentPageNumber(1)
      setTableLoading(false)
    })
  }

  function handlePagination(pageNumber) {
    if (totalCount > checkInData.checkIns.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { checkIns } = fetchMoreResult
          setTableLoading(false)
          return checkIns.length ? { checkIns: [...checkIns] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Check Ins" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Check Ins</Title>
          <div>
            <Button type="primary" size='large' onClick={() => setShowExportModal(true)} style={{ margin: 5 }}>Export</Button>
            <Button type="primary" size='large' onClick={() => refresh()}>Refresh</Button>
          </div>
        </div>
        <Table
          bordered
          loading={networkStatus === 1 || tableLoading || checkInLoad}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
          scroll={{ x: true }}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
        <ReverseModal
          saveFormRef={saveFormRef}
          shoModal={showModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleCreate}
          editableData={editableData}
        />
        <ReverseDetailsModal
          visible={showReverseDetailsModal}
          onCancel={handleCancel}
          editableData={editableData}
        />
        <ExportModal
          formRef={exportFormRef}
          shoModal={showExportModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleExport}
          singleRestaurant={false}
        />
      </Col>
    </Row>
  )
}
