import React, { useState, useRef, } from 'react'
import { Row, Col, Table, Typography, Pagination, Button, Icon, Tooltip, Popconfirm, Switch, notification, Input } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { UPDATE_PLAN, CREATE_PLAN } from './graphql/Mutations'
import { PLANS_CONNECTION, GET_PLANS } from './graphql/Queries'
import client from '../../apollo'
import AddPlanModal from './components/AddPlanModal'
import Meta from '../../components/Meta'
import './plan.css'

const { Title } = Typography

export default function Plans(props) {
  const [tableLoading, setTableLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [planEditableData, setPlanEditableData] = useState("")
  const [isSubmit, setSubmit] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState("name_ASC")
  const [filters, setFilters] = useState({ deletedAt: null })
  const { data: planData, error: planError, loading: planLoad, fetchMore } = useQuery(GET_PLANS, { variables: { filters, orderByFilter, first: 10 }, fetchPolicy: 'network-only' })
  const { data: planDataCount, loading: countLoading, error: planDataCountError } = useQuery(PLANS_CONNECTION, { variables: { filters, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !planDataCountError ? (planDataCount && planDataCount.plansConnection) ? planDataCount.plansConnection.aggregate.count : 1 : 1
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setPlanEditableData("")
  }

  function handleShowModal(b) {
    setShowModal(b)
  }

  function handleRequestFail() {
    setTableLoading(false)
    openNotification("error", "Something Went Wrong")
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const { planName, validity, points, price, type, renewAmount } = values
      const data = {
        name: planName,
        validity: parseInt(validity),
        points: parseInt(points),
        price: parseInt(price),
        type: type,
        renewAmount: parseInt(renewAmount)
      }
      if (planEditableData) {
        try {
          client.mutate({
            mutation: UPDATE_PLAN,
            variables: {
              data,
              id: planEditableData.id
            },
            refetchQueries: [{
              query: GET_PLANS,
              variables: { filters, orderByFilter, first: 10 },
              fetchPolicy: 'network-only'
            }]
          }).then(res => {
            if (res) {
              openNotification('success', 'Plan updated successfully!');
              setPlanEditableData("")
              setShowModal(false)
              setSubmit(false)
              form.resetFields()
            }
          }).catch(e => {
            setSubmit(false)
            console.log(e)
          })
        } catch (e) {
          setSubmit(false)
          openNotification('error', 'Something Went Wrong!')
          console.log(e)
        }
      } else {
        try {
          data.isDisabled = false
          client.mutate({
            mutation: CREATE_PLAN,
            variables: {
              data
            },
            refetchQueries: [{
              query: GET_PLANS,
              variables: { filters, orderByFilter, first: 10 },
              fetchPolicy: 'network-only'
            }]
          }).then(res => {
            if (res) {
              openNotification('success', 'Plan added successfully!');
              setPlanEditableData("")
              setShowModal(false)
              setSubmit(false)
              form.resetFields()
            }
          }).catch(e => {
            setSubmit(false)
          })
        } catch (e) {
          console.log(e)
          setSubmit(false)
          openNotification('error', 'Something Went Wrong!')
        }
      }
    })
  }

  async function handleActive(planId, status) {
    setTableLoading(true)
    await client.mutate({
      mutation: UPDATE_PLAN,
      variables: { data: { isDisabled: !status }, id: planId }
    })
      .then(async updateUserResult => {
        setTimeout(() => {
          openNotification("success", `Plan ${status ? 'Activated' : 'Deactivated'}!`)
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        handleRequestFail()
      })
  }

  function handleEditButton(data) {
    setPlanEditableData(data)
    setShowModal(true)
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={input => input && input.focus()}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      sorter: (a, b) => a.age - b.age
    }
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    setCurrentPageNumber(1)
    confirm()
    let tempFilters = {}
    if (dataIndex === 'name' && selectedKeys[0]) {
      tempFilters["name_contains"] = selectedKeys[0]
    }
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters();
    let tempFilters = {}
    if (dataIndex === 'name') {
      tempFilters["name_contains"] = ""
    }
    setFilters({ ...filters, ...tempFilters })
  }

  const column = [
    {
      title: 'Plan Name',
      dataIndex: 'name',
      width: '20%',
      render: (name) => (name ? name : '-'),
      ...getColumnSearchProps('name')
    },
    {
      title: 'Plan Status',
      dataIndex: 'isDisabled',
      width: '15%',
      render: (isDisabled) => (isDisabled === null ? '-' : isDisabled ? 'Inactive' : 'Active'),
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Plan Type',
      dataIndex: 'type',
      width: '15%',
      render: (type) => (type === 'PUBLIC' ? 'Public' : type === 'PRIVATE' ? 'Private' : '-'),
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Validity',
      dataIndex: 'validity',
      render: (validity) => (validity ? validity : '-'),
      width: '10%',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Points',
      dataIndex: 'points',
      render: (points) => (points ? points : '-'),
      width: '10%',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (price) => (price ? price : '-'),
      width: '10%',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '10%',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon type="edit" theme="twoTone" onClick={() => handleEditButton(record)} />
            </Tooltip>
            &emsp;
            <Popconfirm
              title={`Are you sure you want to ${!record.isDisabled ? 'deactivate' : 'activate'} this plan?`}
              onConfirm={() => handleActive(record.id, record.isDisabled)}
            >
              <Tooltip title={`${!record.isDisabled ? 'Deactivate' : 'Activate'}`}>
                <Switch size="small" defaultChecked={!record.isDisabled} checked={!record.isDisabled} /*onChange={(e)=>handleActive(record.id, e)}*/ />
              </Tooltip>
            </Popconfirm>
          </div>
        )
      }
    }
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!planError && planData && planData.plans) {
    postTableData = planData.plans.map((plan, key) => (
      {
        key: key.toString(),
        id: plan.id,
        name: plan.name,
        points: plan.points,
        validity: plan.validity,
        price: plan.price,
        isDisabled: plan.isDisabled,
        type: plan.type,
        renewAmount: plan.renewAmount
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > planData.plans.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { plans } = fetchMoreResult
          setTableLoading(false)
          return plans.length ? { plans: [...plans] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Plans" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Plans</Title>
          <Button type="primary" size='large' onClick={() => handleShowModal(true)}>Add Plan</Button>
        </div>
        <Table
          scroll={{ x: true }}
          bordered
          loading={planLoad || countLoading || tableLoading}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
        <AddPlanModal
          saveFormRef={saveFormRef}
          shoModal={showModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleCreate}
          planEditableData={planEditableData}
        />
      </Col>
    </Row>
  )
}
