import React, { useContext } from 'react'
import { Row, Col, PageHeader, Button, Tabs, Typography } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_RESTAURANT } from './../graphql/Queries'
import Spinner from '../../../components/loaders/Spinner'
import Page404 from '../../../components/Page404'
import { AppContext } from '../../../AppContext'
import Meta from '../../../components/Meta'
import Payments from './Commission'
import CheckIns from './CheckIns'
import Details from './Details'
import Staff from './Staff'
import '../restaurants.css'

const { Title } = Typography
const { TabPane } = Tabs
const getDay = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

export default function RestaurantDetailPage({ history, match: { params: { restaurantId } } }) {
  const { state } = useContext(AppContext)
  const { data, error, loading, networkStatus } = useQuery(GET_RESTAURANT, { variables: { id: restaurantId }, fetchPolicy: "network-only" })
  const restaurantData = !loading && !error && data && data.restaurant ? data.restaurant : null
  let postTableData = []
  if (restaurantData) {
    postTableData = data.restaurant.restaurantTimings.map((day, key) => (
      {
        day: getDay[day.day],
        slot2: day.timingSlots[1],
        slot1: day.timingSlots[0]
      }
    ))
  }

  const isRestaurantMember = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'RESTAURANT_OWNER' || state.currentUser.role === 'RESTAURANT_STAFF')
  const canViewDetails = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'RESTAURANT_OWNER' || state.currentUser.role === 'ADMIN')
  return (
    networkStatus === 1 || loading ? <Spinner /> :
      error ? <Page404 /> :
        restaurantData ?
          <div className={`restaurant-wrapper ${isRestaurantMember ? 'restaurant-member' : ''}`}>
            <PageHeader
              title={<Title level={3}>{restaurantData.name}</Title>}
              onBack={() => history.push('/restaurants')}
              className="box"
              subTitle={restaurantData.address}
              extra={[
                <Button onClick={() => history.push(`/restaurants/${restaurantId}/edit`)}>Edit</Button>
              ]}
            ></PageHeader>
            <div className="newsChat-detailpage-wrapper">
              <Row gutter={24} type="flex" className="news-chat-wrapper">
                <Meta
                  title="Restaurant Details"
                  description="Restaurant Details"
                />
                <Col span={24}>
                  <Tabs defaultActiveKey="1" onChange={null} size="large">
                    <TabPane tab="Details" key="1">
                      <Details canViewDetails={canViewDetails} postTableData={postTableData} restaurantLoad={loading} restaurantData={data.restaurant} />
                    </TabPane>
                    <TabPane tab="Check Ins" key="2">
                      <CheckIns />
                    </TabPane>
                    {
                      canViewDetails &&
                      <TabPane tab="Payments" key="3">
                        <Payments />
                      </TabPane>
                    }
                    {
                      canViewDetails &&
                      <TabPane tab="Users" key="4">
                        <Staff />
                      </TabPane>
                    }
                  </Tabs>
                </Col>
              </Row>
            </div>
          </div>
          :
          <Page404 />
  )
}
