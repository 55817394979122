import React, { useRef, useState, useContext, Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Row, Col, PageHeader, Tabs, Typography, Popconfirm, Button, notification, Statistic } from 'antd'
import moment from 'moment'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import { AppContext } from '../../AppContext'
import { GET_CURRENT_PLAN, USER_PROFILE_DETAIL, GET_USER } from './graphql/Queries'
import { SET_PLAN, CREATE_TOPUP } from './graphql/Mutations'
import client from '../../apollo'
import PlansHistory from './components/PlansHistory'
import TransactionsHistory from './components/TransactionsHistory'
import TopupModal from './components/TopupModal'
import TopupHistory from './components/TopupHistory'
import Logs from './components/Logs'
import './customer.css'

const { TabPane } = Tabs;
const { Title } = Typography

export default function CustomerDetailPage({ history, match: { params: { customerId } } }) {
  const { data: customerData, error: customerError, loading: customerLoad, networkStatus } = useQuery(GET_USER, { variables: { id: customerId }, fetchPolicy: 'network-only' })
  const { data: planData, error: planError, loading: planLoad, planNetworkStatus } = useQuery(GET_CURRENT_PLAN, { variables: { status: "ACTIVE", id: customerId }, fetchPolicy: 'network-only' })
  const { data: profileHistory, } = useQuery(USER_PROFILE_DETAIL, { variables: { status: "ACTIVE", id: customerId }, fetchPolicy: 'network-only' })
  const topupFormRef = useRef()
  const [showTopupModal, setShowTopupModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [userEditableData, setUserEditableData] = useState("")
  const { state } = useContext(AppContext)

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCancel() {
    topupFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowTopupModal(false)
    setUserEditableData("")
  }

  function handleTopupCreate() {
    const form = topupFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      if (!(planData && planData.userSubscriptions && planData.userSubscriptions[0] && planData.userSubscriptions[0].id)) {
        openNotification('error', 'No active plan found. Subscribe to plan before adding topup.')
        return
      }
      setSubmit(true)
      const { amount, userId } = values
      try {

      } catch (error) {

      }
      client.mutate({
        mutation: CREATE_TOPUP,
        variables: {
          data: {
            user: { connect: { id: userId } },
            topUpAmount: parseFloat(amount),
            payment: {
              create: {
                user: { connect: { id: userId } },
                subscription: { connect: { id: planData.userSubscriptions[0].id } },
                amount: parseFloat(amount),
                transactionStatus: "success",
                transactionResponse: "",
                createdBy: { connect: { id: state.currentUser.id } }
              }
            }
          }
        }
      })
        .then(res => {
          openNotification('success', 'Topup added Successfully!');
          setUserEditableData("")
          setShowTopupModal(false)
          setSubmit(false)
          form.resetFields()
        })
        .catch((e) => {
          console.log(e)
          setSubmit(false)
        })
    })
  }

  function handleTopupBtn(data) {
    setUserEditableData(data)
    setShowTopupModal(true)
  }

  function handleRenewBtn(data) {
    if (planData && planData.userSubscriptions && planData.userSubscriptions[0] && planData.userSubscriptions[0].plans && planData.userSubscriptions[0].plans.id) {
      try {
        client.mutate({
          mutation: SET_PLAN,
          variables: {
            data: {
              user: { connect: { id: data.id } },
              plan: { connect: { id: planData.userSubscriptions[0].plans.id } }
            }
          },
          refetchQueries: [{
            query: GET_USER,
            variables: { id: customerId }
          }]
        }).then((e) => {
          openNotification('success', 'Customer Plan Renewed Successfully!');
        }).catch(e => {
          console.log(e)
        })
      } catch (e) {
        console.log(e)
        openNotification('error', 'Something Went Wrong!');
      }
    } else {
      openNotification('error', 'Assign plan before renewing!')
    }
  }

  return (
    networkStatus === 1 || planNetworkStatus === 1 || customerLoad || planLoad ? <Spinner /> :
      (customerError || planError) ? <Page404 > {console.log(planError)}</Page404> :
        customerData && customerData.user && planData ?
          <Fragment>
            <div className={`customer-wrapper`}>

              <PageHeader
                title={<Title level={3}>
                  {`${customerData.user.firstName} ${customerData.user.lastName || ''}`}
                </Title>}
                extra={[
                  <Popconfirm
                    placement="bottomLeft"
                    title={`Are you sure you want to renew plan for this customer?`}
                    onConfirm={() => handleRenewBtn(customerData.user)}
                    disabled={customerData.user.hasUpcomingPlan || customerData.user.planStatus !== 'ACTIVE'}
                  >
                    <Button type="primary" disabled={customerData.user.hasUpcomingPlan || customerData.user.planStatus !== 'ACTIVE'} >Renew </Button>
                  </Popconfirm>,
                  <Button type="primary" disabled={customerData.user.planStatus !== 'ACTIVE'} onClick={() => handleTopupBtn(customerData.user)} >Topup</Button>
                ]
                }
                className="box"
                subTitle={customerData.user.phone || ''}
                onBack={() => history.goBack()}
              />
            </div>
            <Row gutter={16} type="flex" className="news-chat-wrapper">
              <Col span={4} offset={1}>
                <Statistic title="Plan Points" value={customerData.user.points ? customerData.user.points : 0} precision={0} />
              </Col>
              <Col span={4}>
                <Statistic title="Topup Points" value={customerData.user.topUp ? customerData.user.topUp : 0} precision={0} />
              </Col>
              <Col span={4}>
                <Statistic title="Used Points" value={profileHistory && profileHistory.userHistories && profileHistory.userHistories.userTotalPoints ? profileHistory.userHistories.userTotalPoints : 0} precision={0} />
              </Col>
              <Col span={4}>
                <Statistic title="Current Plan" value={planData.userSubscriptions && planData.userSubscriptions[0] && planData.userSubscriptions[0].plans && planData.userSubscriptions[0].plans.name ? planData.userSubscriptions[0].plans.name : '~'} />
              </Col>
              <Col span={4}>
                <Statistic title="Plan Expires On" value={planData.userSubscriptions && planData.userSubscriptions[0] && planData.userSubscriptions[0].endTime ? moment(planData.userSubscriptions[0].endTime).format('DD/MM/YYYY') : '~'} />
              </Col>
              {/* <Col span={4}>
                <Statistic title="Due Amount (₹)" value={10} />
              </Col> */}
            </Row>
            <div className="newsChat-detailpage-wrapper">
              <Row gutter={24} type="flex" className="news-chat-wrapper">
                <Meta title="Restaurant Details" description="Restaurant Details" />
                <Col span={24}>
                  <Tabs defaultActiveKey="1" onChange={null} size='large'>
                    <TabPane tab="Check Ins" key="1">
                      <TransactionsHistory />
                    </TabPane>
                    <TabPane tab="Plans History" key="2">
                      <PlansHistory />
                    </TabPane>
                    <TabPane tab="Topup History" key="3">
                      <TopupHistory />
                    </TabPane>
                    <TabPane tab="Logs" key="4">
                      <Logs />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
            <TopupModal
              saveFormRef={topupFormRef}
              shoModal={showTopupModal}
              isSubmit={isSubmit}
              handleCancel={handleCancel}
              handleCreate={handleTopupCreate}
              userEditableData={userEditableData}
            />
          </Fragment>
          :
          <Page404 />
  )
}
