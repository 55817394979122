import React, { useState, useContext, useEffect } from 'react'
import { Layout, notification } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import LayoutHeader from './components/Header'
import LayoutSider from './components/Sider'
import Page404 from '../components/Page404'
import { AppContext } from '../AppContext'
import client from '../apollo'
import Spinner from '../components/loaders/Spinner'
import Plans from '../modules/plans'
import Restaurants from '../modules/restaurants'
import AddRestaurant from '../modules/restaurants/components/AddRestaurant'
import RestaurantDetailPage from '../modules/restaurants/components/RestaurantDetailPage'
import Customers from '../modules/customers'
import CustomerDetailPage from '../modules/customers/CustomerDetailPage'
import CheckIns from '../modules/checkins'
import Commission from '../modules/commission'
import SystemUsers from '../modules/system-users'
import { GET_CURRENT_USER } from './graphql/Queries'
import './layout.css'

const { Content } = Layout

export default function LayoutWrapper(props) {
  const [collapsed, setCollapsed] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)
  const [restaurant, setRestaurant] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  useEffect(() => {
    client.query({
      query: GET_CURRENT_USER,
    })
      .then(async data => {
        dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
        if (data.data.currentUser.role === 'RESTAURANT_OWNER' || data.data.currentUser.role === 'RESTAURANT_STAFF') {
          setRestaurant(data.data.currentUser.restaurant.id)
        }
        if (data.data.currentUser.role === 'ADMIN') {
          setIsAdmin(true)
        }
        if (data.data.currentUser.role === 'USER') {
          notification['error']({
            message: 'You are not authorized to perform this action!',
            duration: 3
          })
          props.history.push('/logout')
        }
        setIsLoading(false)
      })
      .catch(error => {
        if (error && error.networkError && error.networkError.statusCode === 405) {
          notification['error']({
            message: 'You are not authorized to perform this action! Please contact your admin!',
            duration: 3
          })
          props.history.push('/logout')
        }
        notification['error']({
          message: error.message,
          duration: 3
        })
        setIsLoading(false)
      })
  }, [dispatch, props.history])

  const { location: { pathname } } = props
  return (
    isLoading ?
      <div className="callback-wrapper">
        <Spinner />
      </div>
      :
      state && state.currentUser &&
      <Layout>
        {
          !restaurant &&
          <LayoutSider
            collapsed={collapsed}
            pathname={pathname}
            role={state.currentUser.role}
          />
        }
        <Layout>
          <LayoutHeader
            collapsed={collapsed}
            toggle={() => setCollapsed(!collapsed)}
            isIkoverkMember={restaurant === null}
          />
          <Content className="content-wrapper">
            {
              restaurant ?
                <Switch>
                  <Route exact path='/restaurants/:restaurantId'
                    component={RestaurantDetailPage} />
                  <Route exact path='/restaurants/:restaurantId/edit'
                    component={AddRestaurant} />
                  <Route exact path='/' render={() => <Redirect to={`/restaurants/${restaurant}`} />} />
                  <Route component={Page404} />
                </Switch>
                :
                <Switch>
                  {
                    isAdmin &&
                    <Route exact path='/plans'
                      component={Plans} />
                  }
                  <Route exact path='/restaurants'
                    component={Restaurants} />
                  {
                    isAdmin &&
                    <Route exact path='/restaurants/add'
                      component={AddRestaurant} />
                  }
                  <Route exact path='/restaurants/:restaurantId'
                    component={RestaurantDetailPage} />
                  <Route exact path='/restaurants/:restaurantId/edit'
                    component={AddRestaurant} />
                  <Route exact path='/customers'
                    component={Customers} />
                  <Route exact path='/customers/:customerId'
                    component={CustomerDetailPage} />
                  {
                    isAdmin &&
                    <Route exact path='/system-users'
                      component={SystemUsers} />
                  }
                  <Route exact path='/checkins'
                    component={CheckIns} />
                  {
                    isAdmin &&
                    <Route exact path='/payment'
                      component={Commission} />
                  }
                  <Route exact path='/' render={() => <Redirect to={isAdmin ? '/plans' : 'restaurants'} />} />
                  <Route component={Page404} />
                </Switch>
            }
          </Content>
        </Layout>
      </Layout>
  )
}
