import React, { useState } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { Form, Input, Select, Row, Col, DatePicker } from 'antd'
import { GET_PLANS_WITH_FILTER } from '../graphql/Queries'

const { Option } = Select

export default function (props) {
  const { data: planData, loading: planLoad, } = useQuery(GET_PLANS_WITH_FILTER, { variables: { isDisabled: false }, fetchPolicy: 'network-only' })
  const { getFieldDecorator, firstName, lastName, phone, email, planId, hasUpcomingPlan, endTime } = props
  const [validity, setValidity] = useState(endTime ? endTime : null)
  const [showExpiry, setShowExpiry] = useState(planId ? true : false)
  let plans = []

  if (planData && planData.plans) {
    plans = planData.plans.map((plan, key) => (
      {
        key: key.toString(),
        id: plan.id,
        name: plan.name,
        validity: plan.validity
      }
    ))
  }

  function handleExpiry(plan) {
    planId === plan.id ? setValidity(moment().add(plan.validity - 1, "days")) : setValidity(moment(endTime ? endTime : moment()).add(plan.validity - 1, "days"))
  }

  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <Form.Item label="First Name">
        {getFieldDecorator('firstName', {
          rules: [{ required: true, message: 'Please write the First Name' }],
          initialValue: firstName
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator('lastName',
          {
            rules: [{ required: true, message: "Please write the Last Name" }],
            initialValue: lastName
          })(<Input />)}
      </Form.Item>
      <Form.Item label="Contact Number">
        {getFieldDecorator('phone',
          {
            rules: [{ required: true, message: "Please write the Contact Number" },
            { len: 10, message: 'Phone number should consist of 10 digits.' },
            { pattern: /^\d+$/g, message: 'Phone number should consist digits only.' }
            ],
            initialValue: phone
          })(<Input type="number" disabled={phone ? true : false} />)}
      </Form.Item>
      <Form.Item label="Email Address">
        {getFieldDecorator('email',
          {
            rules: [{
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/g, message: 'The input is not valid Email!',
            }],
            initialValue: email
          })(<Input disabled={email ? true : false} />)}
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item label="Assign Plan">
            {getFieldDecorator('planId',
              {
                initialValue: planId
              })(<Select loading={planLoad} disabled={hasUpcomingPlan} onChange={() => setShowExpiry(true)}>
                {plans ? (plans.map((plan, i) => (<Option key={i} value={plan.id} onClick={() => handleExpiry(plan)}>{plan.name}</Option>))) : 'Loading..'}
              </Select>)}
          </Form.Item>
        </Col>
      </Row>
      {
        showExpiry ?
          <Form.Item label="Plan Expires On">
            {getFieldDecorator(`customValidity`, {
              initialValue: validity ? moment(validity) : null
            })(<DatePicker format='DD-MM-YYYY' style={{ width: '100%' }} disabledDate={current => { return current < moment().add(-1, 'day'); }} />)}
          </Form.Item>
          :
          null
      }
    </Form>
  )
}
